import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/AboutUs'
import Portfolio from './components/Portfolio'
import Footer from './components/Footer'
import logo from './logo.svg';

const App = () => {
  return (
    <BrowserRouter>
      <div className='content'>


      <Navbar/>
      <Switch>
        <Route exact path={"/"}>
          <Home/>
        </Route>
        <Route exact path={"/about"}>
          <About/>
        </Route>
        <Route exact path={"/portfolio"}>
          <Portfolio/>
        </Route>
      </Switch>
      

      <Footer/>
      </div>
      
    </BrowserRouter>
  );
}

export default App;
