import imgOne from '../assets/sertifikatone.png'
import imgTwo from '../assets/sertifikatwo.png'
import imgThree from '../assets/sertifikatthree.png'
import imgFour from '../assets/ISO 9001,2015 001.pdf'
import imgFive from '../assets/ISO 14001,2015 001.pdf'
import imgSix from '../assets/ISO 45001,2018 001.pdf'

const About = () => {
  return (
    <ul className="aboutus">
      <li className="visimisi">
        <div className="visi">
          <h2>Visi</h2>
          <p><span>"</span>Mewujudkan Impian Pelanggan terhadap produk dan Jasa Konstruksi berkualitas melalui keunggulan Inovasi, Sistem Manajemen dan Menjadi Perusahaan Swasta Nasional terdepan di Bidang Konstruksi dan memberikan kesejahteraan Karyawan, Pengurus, Pemegang Saham dan Stake Holder lainnya melalui Komitmen dan Tata Kelola Perusahaan yang baik<span>"</span></p>
        </div>
        <div className="misi">
          <h2>Misi</h2>
          <p><span>"</span>Selalu menjaga komitmen untuk memberikan kepuasan pelanggan dengan memperhatikan Kualitas, Harga yang sesuai dan Waktu Pekerjaan yang tepat dalam setiap perkerjaan yang dilaksanakan.<span>"</span></p>
        </div>
      </li>
      <li className="nilaiwrap">
        <h2>Nilai Nilai</h2>
        <div className="nilainilai">
          <div><span>Dipercaya</span> oleh Konsumen dan Mitra Strategis dengan menjunjung tinggi kejujuran, tanggung jawab, disiplin dan keterbukaan dalam upaya memenuhi komitmen .</div>
          <div><span>kebersamaan</span> Antara Pengurus Perusahaan, Karyawan dan Mitra Strategis berdasarkan kepada sinergi, persatuan, semangat gotong royong demi tercapainya visi dan misi Perusahaan.</div>
          <div><span>Tanggap</span> dalam mengatisipasi perubahan dinamika usaha, mengidentifikasi potensi dan mencegah timbulnya masalah, serta kreatif dan mampu menemukan solusi terbaik bagi Perusahaan</div>
          <div><span>Unggul</span> dengan berpegang pada Profesionalisme, tahan uji dan kreatifitas dalam upaya meningkatkan kualitas produk dan layanan melalui pengembangan desain, teknologi dan metode konstruksi yang efisien dan efektif</div>
          <div><span>Kepuasan</span> Pelanggan menjadi tujuan utama dengan tetap memperhatikan etika bisnis dan mengacu kepada ketepatan waktu, mutu, produk, biaya dan serta memperhatikan pelestarian lingkungan dan K3(Keselamatan dan Kesehatan Kerja</div>
        </div>
      </li>
      <li className="sertifikat">
        <h3>Sertificate Section</h3>
        <div className='sertList'>
          <img src={imgOne} />
          <img src={imgTwo} />
          <img src={imgThree} />
          <embed src={imgFour} />
          <embed src={imgFive} />
          <embed src={imgSix} />
        </div>
        
      </li>
    </ul>
  )
}

export default About