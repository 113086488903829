import { Link } from "react-router-dom";
import img from '../assets/logo.png';
import imgtwo from '../assets/logothree.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div >
        <img src={imgtwo} alt="PT. SETIA MULIA ABADI" className="pointer"/>
      </div>
      <ul>
        <li>
          <Link to={"/"}>Beranda</Link>
        </li>
        <li>
          <Link to={"/about"}>Tentang Kami</Link>
        </li>
        <li>
          <Link to={"/portfolio"}>Portofolio</Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar;