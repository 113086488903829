import imgOne from '../assets/carouseltwo.png'
import imgTwo from '../assets/carouselfour.png'
import imgThree from '../assets/carouselthree.png'
import imgFour from '../assets/portone.png'
import imgFive from '../assets/porttwo.png'
import imgSix from '../assets/portthree.png'

const Portfolio =  () => {
  return (
    <ul className="portfolio">
      <h2>Timeline</h2>
      <li className='portpart'>
        <div>
          <h3>2000</h3>
          <p>- Ancilaries building pada Proyek Refinery 2000 TPD PT. Astra Agro Lestari</p>
        </div>
        <div>
        </div>
      </li>
      <li>
        <div>
          <h3>2002</h3>
          <p>- Pembangunan Dermaga Lokodidi Tahap I </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2003</h3>
          <p>- Pembangunan Dermaga Lokodidi Tahap II </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2005</h3>
          <p>- Reklamasi Pantai Kolonedale </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2006</h3>
          <p>- Pembangunan Fasilitas Pelabuhan Laut Tangkiang Tahap I </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2007</h3>
          <p>- Pembangunan Dermaga Penyebrangan Tahap IV </p>
          <p>- Pembangunan Fasilitas Pelabuhan Tangkiang Tahap II </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2008</h3>
          <p>- Pembangunan Fasilitas Pelabuhan Laut Banggai Tahap I </p>
          <p>- Pembangunan Trestle + Pelebaran dan Pengaspalan Lapangan Penumpukan Kolonedale </p>
          <p>- Lanjutan Pembangunan Faspel Leok Tahap I </p>
          <p>- Pembangunan Dermaga Penyebrangan Gunaksa Tahap I </p>
        </div>
        <div>
          <img src={imgFour}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2009</h3>
          <p>- Lanjutan Pembangunan Fasilitas Laut Banggai Tahap II </p>
          <p>- Pembangunan Pelabuhan Penyebrangan Gunaksa </p>
          <p>- Pembangunan Pelabuhan Penyebrangan Padangbai Tahap II (Adhi - Setia Mulia, JO)</p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2010</h3>
          <p>- Lanjutan Pembangunan Fasilitas Pelabuhan Leok Tahap III </p>
          <p>- (2008 - 20010) Peningkatan Sarana Pendukung Pangkalan Sarana Operasi Bea dan Cukai Tipe B Pantoloan (Adhi - Setia Mulai JO)  </p>
          <p>- Pembuatan Bangunan Pengendali Sedimen</p>
          <p>- Lanjutan Pembangunan Dermaga Pelabuhan Laut Parigi</p>
          <p>- Pembangunan Pelabuhan Penyebrangan Baturupe Tahap II</p>
          <p>- Lanjutan Pembangunan Faspel Leok Tahap IV</p>
        </div>
        <div>
          <img src={imgFour}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2011</h3>
          <p>- Pembangunan Fasilitas Pelabuhan Laut Mantangisi </p>
          <p>- Pembangunan Dermaga Penyebrangan Kolonedale Tahap III </p>
          <p>- Pembangunan Dermaga Penyebrangan Bahaur Tahap V </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2012</h3>
          <p>- Penyelesaian Pembangunan Fasilitas Pelabuhan Laut Tangkiang </p>
          <p>- Lanjutan Pembangunan Fasilitas Pelabuhan Laut Banggai Tahap V </p>
          <p>- Pembangunan Dermaga Penyebrangan Salakan Tahap II </p>
          <p>- Pembangunan Dermaga Penyebrangan Bahaur Tahap VII </p>
          <p>- Pekerjaan Pembangunan Gedung Terminal Tahap II Bandar Udara Mutiara Palu (Adhi - Setia Mulia, JO) </p>
        </div>
        <div>
          <img src={imgFive}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2013</h3>
          <p>- Penyelesaian Pembangunan Fasilitas Pelabuhan Laut Donggala </p>
          <p>- Penyelesaian Pembangunan Fasilitas Pelabuhan Laut Malenge </p>
          <p>- Penyelesaian Pembangunan Fasilitas Pelabuhan Laut Pagimana </p>
          <p>- Replacement Dermaga Parigi </p>
          <p>- Pembangunan Jalan Masuk di Lingkungan Pelabuhan Kumai Tahap III </p>
          <p>- Ancilaries Building pada Project Refinery 2000 MPTD PT. Astra Argo Lestari </p>
        </div>
        <div>
          <img src={imgOne}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2014</h3>
          <p>- Pekerjaan Pembangunan Jalan Masuk Pelabuhan Laut Kumai Tahap IV </p>
          <p>- Pekerjaan Lanjutan Pembangunan Fasilitas Pelabuhan Laut Mantangisi </p>
          <p>- Pekerjaan Pembangunan Gedung Dharma Wanita Luwuk Tahap I </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2015</h3>
          <p>- Pekerjaan Pembangunan Landing Site B Asset 5 Field Sanga-sanga PT. Pertamina EP Asset 5 </p>
          <p>- Pekerjaan Pembangunan Jetty Site B Asset 5 Field Sanga-sanga PT. Pertamina EP Asset 5 </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2016</h3>
          <p>- Pekerjaan Pembangunan Fasilitas Pelabuhan Laut Tilamuta </p>
          <p>- Pekerjaan Pembangunan Rumah Dinas Susun Luas 552 m2 dan 900 m2 di Pangkalan Saran Operasi Bea dan Cukai Tipe B Pantoloan </p>
          <p>- Pekerjaan Pembangunan /Pemasangan SHS 100 WP di Kabupaten Buol </p>
          <p>- Pekerjaan Pembangunan /Pemasangan SHS 100 WP di Kabupaten Morowali </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2017</h3>
          <p>- Pekerjaan Pembangunan Fasilitas Pelabuhan Laut Paleleh </p>
          <p>- Pekerjaan Pembangunan Pagar SPN Polda Sulteng</p>
          <p>- Pekerjaan Pembangunan Kompleks Pergudangan Di Area Utara Mahakam Field Sangasanga </p>
          <p>- Pekerjaan Pembangunan Dua Unit Dermaga dan Perbaikan Satu unit Landing Site Di Asset 5 Tarakan Field Area Sembakung</p>
        </div>
        <div>
        <img src={imgSix}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2018</h3>
          <p>- Pekerjaan Pembangunan Fasilitas Pendukung Akademi Komunitas di Kawasan Industri Bantaeng Tahap II </p>
          <p>- Pekerjaan Pembangunan Gedung Pengolahan Sabut dan Pematangna Lahan </p>
        </div>
        <div>
        <img src={imgThree}/>
        </div>
      </li>
      <li>
        <div>
          <h3>2019</h3>
          <p>- Pekerjaan Pembangunan Gedung Dormitory Pendidikan di Akademi Komunitas Industri Tekstil dan Produksi Tekstil Surakarta </p>
          <p>- Pembangungan Workshop I Politeknik Industri Furnitur dan Pengolahan Kayu Kendal </p>
        </div>
        <div>
          {/* image */}
        </div>
      </li>
      <li>
        <div>
          <h3>2020</h3>
          <p>- Pekerjaan Pembangunan Gedung Wanita Propinsi Sulawesi Tengah </p>
          <p>- Pekerjaan Pembangunan Gedung Kantor BPTD Propinsi Sulawesi Tengah </p>
          <p>- Pekerjaan Rehabilitasi Terminal Penumpang Tiper A Mengwi-Bali </p>
          <p>- Pekerjaan Rehabilitasi dan Rekonstruksi Universitas Tadulako Fase IC </p>
        </div>
        <div>
        <img src={imgTwo}/>
        </div>
      </li>
    </ul>
  )
}

export default Portfolio;