import carouselOne from '../assets/carouselone.png'
import carouselTwo from '../assets/carouseltwo.png'
import carouselThree from '../assets/carouselthree.png'
import carouselFour from '../assets/carouselfour.png'
import about from '../assets/about.png'
import { useEffect, useState } from 'react'


const Home = () => {
  const imgArr = [carouselOne, carouselTwo, carouselThree, carouselFour]
  let [index, setIndex] = useState(0)
  let [displayEffect, setDisplayEffect] = useState('swOn')
  // let index = 0;
  const delay = (sec) => {
    return new Promise(resolve => {
      setTimeout(resolve, sec)
    })
  }

  useEffect(()=> {
    const interval = setInterval( async () => {
      setDisplayEffect('swOff')  
      const delays = await delay(1000)
      console.log("off")
      if (index < imgArr.length-1) {
        setDisplayEffect('swOn')
        setIndex(index + 1) 
        console.log("on")
      } else {
        setDisplayEffect('swOn')
        setIndex(0)
        console.log("on")
      }
      
      
    }, 5000)
    
    return () => {
      clearInterval(interval);
    }
  })

  return (
    <div className="home">
      <ul className="carousel">
        <li className={displayEffect}>
          <img src={imgArr[index]} />
        </li>
      </ul>
      <section className='about' >
        <h1>Mengenai Kami</h1>
        <div className='aboutDetail'>
          <img src={about} />
          <div>
            <h2>PT. Setia Mulia Abadi didirikan pada</h2>
            
            <p><span>"</span>
               pertengahan tahun 1995 dan selama lebih dari 15 tahun bergerak sebagai kontraktor umum dan Supplier guna mendukung program pemerintah dalam bidang pemerataan pembangunan. Berpusat di Kota Palu, Sulawesi Tengah.  
            </p>
            <p>
            PT. Setia Mulia Abadi telah turut serta dalam pembangunan di beberapa provinsi di Indonesia dan berupaya untuk selalu mengembangkan diri demi tercapinya visi perusahaan.
            <span>"</span></p>
          </div>
          
        </div>
      </section>
    </div>
  )
}

export default Home;