import footerOne from "../assets/footer.png";
import footerTwo from "../assets/footer-two.png";
import footerThree from "../assets/footerthree.png";
import footerFour from "../assets/footerfour.png";

const Footer = () => {
  return (
    <ul className="footer">
        <li>
          <h3>Hubungi Kami</h3>
          <div>
            <p>- Phone: 0451 426435</p>
            <p>- Fax: 0451 457334</p>
            <p>- Mail: palu_ptsma@yahoo.com</p>
          </div>
        </li>
        <li>
          <h3>Kantor Utama</h3>
          <div>
            <p>- Jalan Kelinci No.2</p>
            <p>- Palu - Sulawesi Tengah</p>
          </div>
        </li>
        <li>
          <img src={footerThree} />
        </li>
        <li>
        <img src={footerFour} />
        </li>
    </ul>
  );
};
export default Footer;
